import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconArrowRight.module.css';

const IconArrowRight = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        d="M2.5 8h11M9 3.5 13.5 8 9 12.5"
        stroke="#4A4A4A"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconArrowRight.defaultProps = {
  className: null,
  rootClassName: null,
};

IconArrowRight.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconArrowRight;
