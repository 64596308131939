import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.module.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instragramLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  const torontoLinkText = (
    <span className={css.highlighted}>{intl.formatMessage({ id: 'Footer.torontoLinkText' })}</span>
  );
  const oshawaLinkText = (
    <span className={css.highlighted}>{intl.formatMessage({ id: 'Footer.oshawaLinkText' })}</span>
  );
  const vaughanLinkText = (
    <span className={css.highlighted}>{intl.formatMessage({ id: 'Footer.vaughanLinkText' })}</span>
  );
  const oakvilleLinkText = (
    <span className={css.highlighted}>{intl.formatMessage({ id: 'Footer.oakvilleLinkText' })}</span>
  );
  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <Logo format="desktop" place="footer" className={css.logo} />
              </NamedLink>
              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationDescription" />
                </p>
              </div>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <span className={css.link}>
                    <FormattedMessage id="Footer.getStarted" />
                  </span>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="NewListingPage" className={css.link}>
                    List gym space
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="SearchPage" className={css.link}>
                    <FormattedMessage id="Footer.toSearchPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="SubscriptionPage" className={css.link}>
                    Subscription
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.searches}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <span className={css.link}>
                    <FormattedMessage id="Footer.learnMore" />
                  </span>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" className={css.link}>
                    About us
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="HowItWorksPage" className={css.link}>
                    How it works
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="TrustSafetyPage" className={css.link}>
                    Trust & Safety
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="ContactPage" className={css.link}>
                    Contact
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.searchesExtra}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <span className={css.link}>
                    <FormattedMessage id="Footer.bookNow" />
                  </span>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        "?address=Toronto%2C%20Ontario%2C%20Canada&bounds=43.855465974%2C-79.11552021%2C43.581071012%2C-79.639302909'",
                    }}
                    className={css.link}
                  >
                    <FormattedMessage
                      id="Footer.bookNowLink"
                      values={{ linkText: torontoLinkText }}
                    />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Oshawa%2C%20Ontario%2C%20Canada&bounds=44.04739269%2C-78.796185001%2C43.850153002%2C-78.959388418',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage
                      id="Footer.bookNowLink"
                      values={{ linkText: oshawaLinkText }}
                    />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Vaughan%2C%20Ontario%2C%20Canada&bounds=43.924272774%2C-79.420073015%2C43.749845014%2C-79.71151314',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage
                      id="Footer.bookNowLink"
                      values={{ linkText: vaughanLinkText }}
                    />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Oakville%2C%20Missouri%2C%20United%20States&bounds=38.492030999%2C-90.278754%2C38.388297999%2C-90.360618',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage
                      id="Footer.bookNowLink"
                      values={{ linkText: oakvilleLinkText }}
                    />
                  </NamedLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={css.bottomCopyright}>
        <div className={css.copyrightWrapper}>
          <FormattedMessage id="Footer.copyright" />
          <div className={css.socialMediaLinks}>{socialMediaLinks}</div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
