import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldSelect } from '../../components';

import css from './EditListingDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 60;

const generateHours = intl => {
  const hoursArray = [...Array(12).keys()];
  const hours = hoursArray.map(h => {
    const hourNumber = h + 1;
    const formattedHour = intl.formatMessage(
      { id: 'EditListingDescriptionForm.formattedHour' },
      { number: hourNumber }
    );
    return {
      key: hourNumber,
      label: formattedHour,
    };
  });

  return hours;
};

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        courtOptions,
        isCurrentUserPremium,
      } = formRenderProps;

      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const courtMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.court' });
      const courtPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.courtPlaceholder',
      });

      const videoLinkMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.videoLink' });
      const videoLinkPredefinedValue = intl.formatMessage({
        id: 'EditListingDescriptionForm.videoLinkPredefinedValue',
      });

      const facebookLinkMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.facebookLink',
      });
      const facebookLinkPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.facebookLinkPlaceholder',
      });
      const facebookLinkPredefinedValue = intl.formatMessage({
        id: 'EditListingDescriptionForm.facebookLinkPredefinedValue',
      });

      const instagramLinkMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.instagramLink',
      });
      const instagramLinkPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.instagramLinkPlaceholder',
      });
      const instagramLinkPredefinedValue = intl.formatMessage({
        id: 'EditListingDescriptionForm.instagramLinkPredefinedValue',
      });

      const minimumBookingMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.minimumBooking',
      });
      const minimumBookingPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.minimumBookingPlaceholder',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          />

          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          />

          <FieldSelect className={css.selectField} id="court" name="court" label={courtMessage}>
            <option disabled value="">
              {courtPlaceholder}
            </option>
            {courtOptions.map(c => {
              return (
                <option key={c.key} value={c.key}>
                  {c.label}
                </option>
              );
            })}
          </FieldSelect>

          <FieldSelect
            className={css.selectField}
            id="minimumBooking"
            name="minimumBooking"
            label={minimumBookingMessage}
          >
            <option disabled value="">
              {minimumBookingPlaceholder}
            </option>
            {generateHours(intl).map(h => {
              return (
                <option key={h.key} value={h.key}>
                  {h.label}
                </option>
              );
            })}
          </FieldSelect>
          {isCurrentUserPremium ? (
            <>
              <FieldTextInput
                id="videoLink"
                name="videoLink"
                className={css.linkField}
                type="text"
                label={videoLinkMessage}
                predefinedValue={videoLinkPredefinedValue}
              />

              <FieldTextInput
                id="socials.facebook"
                name="socials.facebook"
                className={css.linkField}
                type="text"
                label={facebookLinkMessage}
                placeholder={facebookLinkPlaceholder}
                predefinedValue={facebookLinkPredefinedValue}
              />

              <FieldTextInput
                id="socials.instagram"
                name="socials.instagram"
                className={css.linkField}
                type="text"
                label={instagramLinkMessage}
                placeholder={instagramLinkPlaceholder}
                predefinedValue={instagramLinkPredefinedValue}
              />
            </>
          ) : null}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
