import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-toronto',
    predictionPlace: {
      address: 'Toronto, Ontario, Canada',
      bounds: new LatLngBounds(
        new LatLng(43.855465974, -79.11552021),
        new LatLng(43.581071012, -79.639302909)
      ),
    },
  },
  {
    id: 'default-oshawa',
    predictionPlace: {
      address: 'Oshawa, Ontario, Canada',
      bounds: new LatLngBounds(
        new LatLng(44.04739269, -78.796185001),
        new LatLng(43.850153002, -78.959388418)
      ),
    },
  },
  {
    id: 'default-vaughan',
    predictionPlace: {
      address: 'Vaughan, Ontario, Canada',
      bounds: new LatLngBounds(
        new LatLng(43.924272774, -79.420073015),
        new LatLng(43.749845014, -79.71151314)
      ),
    },
  },
  {
    id: 'default-pickering',
    predictionPlace: {
      address: 'Pickering, Ontario, Canada',
      bounds: new LatLngBounds(
        new LatLng(44.010769826, -79.008541991),
        new LatLng(43.794306014, -79.22004376)
      ),
    },
  },
  {
    id: 'default-markham',
    predictionPlace: {
      address: 'Markham, Ontario, Canada',
      bounds: new LatLngBounds(
        new LatLng(43.963481642, -79.170245412),
        new LatLng(43.797992777, -79.428712457)
      ),
    },
  },
];
export default defaultLocations;
