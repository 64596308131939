import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { IconArrowRight } from '..';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '..';

import css from './SectionCategories.module.css';

import fullCourtGymImage from './images/full-court-gym.png';
import halfCourtGymImage from './images/half-court-gym.png';
import weightTrainingGymImage from './images/weight-training-gym.png';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery) => {
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.overlay}>
        <div className={css.linkText}>{name}</div>
      </div>
    </NamedLink>
  );
};

const SectionCategories = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionCategories.title" />
      </div>
      <div className={css.locations}>
        {locationLink('Full court gyms', fullCourtGymImage, '?pub_court=full-court')}
        {locationLink('Half court gyms', halfCourtGymImage, '?pub_court=half-court')}
        {locationLink('Weight training gyms', weightTrainingGymImage, '?pub_court=weight-training')}
      </div>
      <div className={css.explore}>
        <NamedLink className={css.exploreLink} name="SearchPage">
          <FormattedMessage id="SectionCategories.explore" />
          <IconArrowRight className={css.exploreIcon} />
        </NamedLink>
      </div>
    </div>
  );
};

SectionCategories.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionCategories.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCategories;
