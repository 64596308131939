import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput, FieldCheckbox } from '../../components';
import css from './EditListingPricingForm.module.css';

const { Money } = sdkTypes;

const ADDITIONAL_SERVICES_NAME = 'additional_services';

const shouldAdditionalServicesDisableForm = (additionalServicesFromValues, additionalServices) => {
  return (
    additionalServicesFromValues &&
    Object.keys(additionalServicesFromValues).filter(m => {
      const findService = additionalServices.find(s => s.key === m);
      const findServiceFromValues = additionalServicesFromValues?.[findService?.key]?.length > 0;

      if (findServiceFromValues) {
        const serviceHasPrice = !!additionalServicesFromValues?.[`${m}-price`];

        // If the service is selected and
        // has price don't disable the form
        if (serviceHasPrice) return false;
        // Else disable the form
        else return true;
      } else {
        // If service isn't selected
        // don't disable the form
        return false;
      }
    })
  );
};

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        values,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        additionalServices,
        isCurrentUserPremium,
      } = formRenderProps;

      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
        ? 'EditListingPricingForm.pricePerDay'
        : 'EditListingPricingForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });
      const additionalServicePricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.additionalServicePricePlaceholder',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      // We need to check if user has selected the service
      // but hasn't added the price. In those cases we
      // need to disable the from from submitting
      const additionalServicesFromValues = values['additional_services'];
      const hasUnfinishedServices = shouldAdditionalServicesDisableForm(
        additionalServicesFromValues,
        additionalServices
      );

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled =
        invalid || disabled || submitInProgress || hasUnfinishedServices?.length > 0;
      const { updateListingError, showListingsError } = fetchErrors || {};

      // console.log(values);
      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}

          <FieldCurrencyInput
            id="price"
            name="price"
            className={css.priceInput}
            autoFocus
            label={pricePerUnitMessage}
            placeholder={pricePlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />

          {isCurrentUserPremium ? (
            <div className={css.additionalServices}>
              <h3 className={css.additionalServicesHeading}>
                <FormattedMessage id="EditListingPricingForm.additionalServicesHeading" />
              </h3>
              {additionalServices.map(service => {
                const serviceId = `${ADDITIONAL_SERVICES_NAME}.${service.key}`;
                const serviceLabel = intl.formatMessage(
                  { id: 'EditListingPricingForm.additionalServiceLabel' },
                  { label: service.label }
                );
                return (
                  <div key={service.key} className={css.additionalService}>
                    <FieldCheckbox
                      id={serviceId}
                      name={serviceId}
                      textClassName={css.additionalServiceCheckboxText}
                      label={serviceLabel}
                      value={service.key}
                    />
                    <FieldCurrencyInput
                      id={`${serviceId}-price`}
                      name={`${serviceId}-price`}
                      className={css.additionalServicePriceInput}
                      placeholder={additionalServicePricePlaceholderMessage}
                      currencyConfig={config.currencyConfig}
                    />
                    <p className={css.additionalServiceNotice}>
                      <FormattedMessage id="EditListingPricingForm.additionalServiceNotice" />
                    </p>
                  </div>
                );
              })}
            </div>
          ) : null}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
