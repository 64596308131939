import { useState, useEffect } from 'react';

export const fetchListingBookings = (listingId, onFetchListingBookings) => {
  const [listingBookings, setListingBookings] = useState(0);

  useEffect(() => {
    async function fetchBookings() {
      const result = await onFetchListingBookings(listingId);
      setListingBookings(result);
    }

    fetchBookings();
  }, []);

  return listingBookings;
};
