import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '..';

import classNames from 'classnames';

import css from './SectionAction.module.css';

const SectionAction = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div>
        <div className={css.title}>
          <FormattedMessage id="SectionAction.title" />
        </div>
        <p className={css.subTitle}>
          <FormattedMessage id="SectionAction.subTitle" />
        </p>
        <NamedLink name="HowItWorksPage" className={css.sectionButton}>
          <FormattedMessage id="SectionAction.buttonText" />
        </NamedLink>
      </div>
    </div>
  );
};

SectionAction.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionAction.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionAction;
