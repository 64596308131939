import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCheck.module.css';

const IconCheck = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height="24"
      width="24"
    >
      <g transform="scale(1.71429)">
        <path d="m4 8 2.05 1.64a.48.48 0 0 0 .4.1.5.5 0 0 0 .34-.24L10 4" />
        <circle cx={7} cy={7} r={6.5} />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconCheck.defaultProps = {
  className: null,
  rootClassName: null,
};

IconCheck.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCheck;
