import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { ListingCard, NamedLink, IconArrowRight } from '..';

import classNames from 'classnames';

import css from './SectionListings.module.css';

const SectionListings = props => {
  const { rootClassName, className, listings, onFetchListingBookings } = props;
  const classes = classNames(rootClassName || css.root, className);

  const hasListings = listings?.length > 0;
  return hasListings ? (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionListings.title" />
      </div>
      <div className={css.listings}>
        {listings.map(l => {
          return (
            <ListingCard
              key={l.id.uuid}
              listing={l}
              onFetchListingBookings={onFetchListingBookings}
            />
          );
        })}
      </div>
      <div className={css.explore}>
        <NamedLink className={css.exploreLink} name="SearchPage">
          <FormattedMessage id="SectionListings.explore" />
          <IconArrowRight className={css.exploreIcon} />
        </NamedLink>
      </div>
    </div>
  ) : null;
};

SectionListings.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionListings.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionListings;
