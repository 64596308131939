import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { IconArrowRight } from '../../components';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionLocations.module.css';

import torontoImage from './images/toronto-gym.png';
import oshawaImage from './images/oshawa-gym.png';
import vaughanImage from './images/vaughan-gym.png';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery) => {
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.overlay}>
        <div className={css.linkText}>{name}</div>
      </div>
    </NamedLink>
  );
};

const SectionLocations = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
      </div>
      <div className={css.locations}>
        {locationLink(
          'Toronto',
          torontoImage,
          '?address=Toronto%2C%20Ontario%2C%20Canada&bounds=43.855465974%2C-79.11552021%2C43.581071012%2C-79.639302909'
        )}
        {locationLink(
          'Oshawa',
          oshawaImage,
          '?address=Oshawa%2C%20Ontario%2C%20Canada&bounds=44.04739269%2C-78.796185001%2C43.850153002%2C-78.959388418'
        )}
        {locationLink(
          'Vaughan',
          vaughanImage,
          '?address=Vaughan%2C%20Ontario%2C%20Canada&bounds=43.924272774%2C-79.420073015%2C43.749845014%2C-79.71151314'
        )}
      </div>
      <div className={css.explore}>
        <NamedLink className={css.exploreLink} name="SearchPage">
          <FormattedMessage id="SectionLocations.explore" />
          <IconArrowRight className={css.exploreIcon} />
        </NamedLink>
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
