import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { IconCheck, PrimaryButton, SecondaryButton, InlineTextButton } from '..';
import { formatMoney } from '../../util/currency';
import { stripeAPI } from '../../util/api';
import { types as sdkTypes } from '../../util/sdkLoader';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';
import classNames from 'classnames';

import css from './PlanCard.module.css';

const { Money } = sdkTypes;

// Plan keys
const PREMIUM_PLAN_KEY = 'premium';

const PlanCard = props => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    intl,
    rootClassName,
    className,
    history,
    location,
    plan,
    active,
    currentUser,
    sessionId,
    setCancelSubscriptionModalOpen,
  } = props;
  const { label, features, price, highlighted } = plan;

  const stripeSubscriptionDetails =
    currentUser.attributes.profile.publicData?.stripeSubscriptionDetails;
  const customerId = stripeSubscriptionDetails?.customerId;

  const formattedPrice = price ? formatMoney(intl, new Money(price, config.currency)) : null;

  const handleCreatingSubscription = async () => {
    if (!currentUser.id) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      if (plan.key === PREMIUM_PLAN_KEY) {
        setIsLoading(true);
        const sessionURL = await stripeAPI.createCheckoutSession({
          lookupkey: 'premiumplan',
          userId: currentUser.id.uuid,
          userEmail: currentUser.attributes.email,
        });

        setIsLoading(false);
        window.location.replace(sessionURL);
      }
    }
  };
  const handleUpdatingSubscription = async () => {
    setIsLoading(true);

    const sessionIdMaybe = sessionId ? { sessionId } : {};
    const customerIdMaybe = customerId ? { customerId } : {};
    const portalSessionURL = await stripeAPI.createPortalSession({
      ...sessionIdMaybe,
      ...customerIdMaybe,
    });

    setIsLoading(false);
    window.location.replace(portalSessionURL);
  };

  const planPrice = formattedPrice?.replace('.00', '');
  const planMonth = (
    <span className={css.month}>{intl.formatMessage({ id: 'PlanCard.month' })}</span>
  );

  const classes = classNames(rootClassName || css.root, className, {
    [css.rootHighlighted]: highlighted,
  });

  return (
    <div className={classes}>
      <div className={css.topWrapper}>
        <div className={css.infoWrapper}>
          <div className={css.info}>
            <h3 className={css.name}>{label}</h3>
            <h3 className={css.price}>
              {price ? (
                <FormattedMessage
                  id="PlanCard.price"
                  values={{
                    price: planPrice,
                    month: planMonth,
                  }}
                />
              ) : (
                <FormattedMessage id="PlanCard.freePrice" />
              )}
            </h3>
          </div>
        </div>
        <ul className={css.features}>
          {features.map((f, index) => {
            return (
              <li key={index} className={css.feature}>
                <IconCheck className={css.checkIcon} />
                <div className={css.featureName}>{f}</div>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={css.bottomWrapper}>
        {highlighted ? (
          <PrimaryButton
            className={css.submitButton}
            onClick={sessionId ? handleUpdatingSubscription : handleCreatingSubscription}
            inProgress={isLoading}
            disabled={active}
          >
            <FormattedMessage
              id={active ? 'PlanCard.buttonTextActive' : 'PlanCard.buttonText'}
              values={{ name: label }}
            />
          </PrimaryButton>
        ) : (
          <SecondaryButton
            className={css.submitButton}
            onClick={() => {
              if (!currentUser.id) {
                const state = {
                  from: `${location.pathname}${location.search}${location.hash}`,
                };

                // signup and return back to listingPage.
                history.push(
                  createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}),
                  state
                );
              } else {
                setCancelSubscriptionModalOpen(true);
              }
            }}
            disabled={active}
          >
            <FormattedMessage
              id={
                currentUser.id
                  ? active
                    ? 'PlanCard.buttonTextActive'
                    : 'PlanCard.buttonTextDowngrade'
                  : 'PlanCard.buttonTextSignup'
              }
              values={{ name: label }}
            />
          </SecondaryButton>
        )}
        <div className={css.submitButtonNotice}>
          {active ? (
            highlighted ? (
              <InlineTextButton onClick={handleUpdatingSubscription}>
                <FormattedMessage id="PlanCard.buttonNoticeActivePremium" />
              </InlineTextButton>
            ) : (
              <FormattedMessage id="PlanCard.buttonNoticeActiveBasic" />
            )
          ) : (
            <FormattedMessage
              id={highlighted ? 'PlanCard.buttonNoticePremium' : 'PlanCard.buttonNoticeBasic'}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const { string, bool, object } = PropTypes;

PlanCard.defaultProps = {
  rootClassName: null,
  className: null,
  active: false,
  sessionId: null,
};

PlanCard.propTypes = {
  rootClassName: string,
  className: string,
  plan: object.isRequired,
  active: bool,
  sessionId: string,
};

export default injectIntl(PlanCard);
