import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconError.module.css';

const IconError = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height="24"
      width="24"
    >
      <g transform="scale(1.71429)">
        <circle cx={7} cy={7} r={6.5} />
        <path d="M7 3.5v3" />
        <circle cx={7} cy={9.5} r={0.5} />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconError.defaultProps = {
  className: null,
  rootClassName: null,
};

IconError.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconError;
