import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TrustSafety.module.css';

const TrustSafety = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: March 31, 2022</p>

      <h2>Guidelines</h2>

      <p>
        In the event of a booking modification or cancellation for any reason, both parties will be
        responsible to contact each other directly.
      </p>

      <p>
        GTASports is not a party to any contractual booking agreement between both parties, we
        solely help people discover private facilities, connect specified trainers, and then help
        them communicate directly for bookings purposes only.
      </p>

      <p>
        GTASports is not responsible for any modifications or cancellations. We encourage both
        parties to communicate in a timely manner through our platform. GTASports does allow its
        users to identify their cancellation policy on their listing directly for informational
        purposes and references.
      </p>
      
      <p>
        By using this platform, you must communicate and agree to any cancellation policy that is
        identified on a listing, through direct messaging or as otherwise communicated by the owner
        of the listing.
      </p>
    </div>
  );
};

TrustSafety.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TrustSafety.propTypes = {
  rootClassName: string,
  className: string,
};

export default TrustSafety;
