import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

import css from './EditListingPricingPanel.module.css';

const { Money } = sdkTypes;

const getAdditionalServicesInitialValues = publicData => {
  const additionalServicesFromPublicData = publicData?.additionalServices;
  const mappedAdditionalServices = additionalServicesFromPublicData?.map(s => {
    return { [s.key]: [s.key], [`${s.key}-price`]: new Money(s.price.amount, s.price.currency) };
  });
  const reducedAdditionalServices = mappedAdditionalServices?.reduce((obj, item) => ({
    ...obj,
    ...item,
  }));

  return reducedAdditionalServices;
};

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    additionalServices,
    isCurrentUserPremium,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price, publicData } = currentListing.attributes;

  const additionalServicesInitialValues = getAdditionalServicesInitialValues(publicData);

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPricingPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingPricingPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
  );

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;
  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      initialValues={{ price, additional_services: additionalServicesInitialValues }}
      onSubmit={values => {
        const { price, additional_services } = values;

        // First map through the additional services
        // and form an array of objects that contains
        // the service informations and its price
        const services =
          additional_services &&
          Object.keys(additional_services).map(m => {
            const isPriceService = additional_services[m]?.amount;
            const hasLength = additional_services[m]?.length > 0;
            return hasLength && !isPriceService
              ? {
                  key: m,
                  label: additionalServices.find(a => a.key === m)?.label,
                  price: {
                    amount: additional_services[`${m}-price`]?.amount,
                    currency: additional_services[`${m}-price`]?.currency,
                  },
                }
              : null;
          });

        // Remove mapped items that returns null
        // and if at the end theres no items in array
        // remove additionalServices from publicData
        const finalAdditionalServices = services?.filter(s => s !== null);
        const additionalServicesMaybe =
          finalAdditionalServices?.length > 0
            ? {
                additionalServices: finalAdditionalServices,
              }
            : { additionalServices: null };

        const updatedValues = {
          price,
          publicData: { ...additionalServicesMaybe },
        };
        onSubmit(updatedValues);
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      additionalServices={additionalServices}
      isCurrentUserPremium={isCurrentUserPremium}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {form}
    </div>
  );
};

const { func, object, string, bool, array } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,

  // Custom config
  additionalServices: config.custom.pricingAdditionalServices,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  // Custom config
  additionalServices: array,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;
